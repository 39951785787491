import { setupBrandNameProperties } from 'AppBranding';
import cacheAngularTemplate from 'cacheAngularTemplate';
import { getActiveInstitution, getCohort, getHasPendingProgramApplication } from 'Users';
import { getProgramFamilyFormDatum } from 'ProgramFamilyFormData';
import { ProgramFamily } from 'Program';
import transformKeyCase from 'Utils/transformKeyCase';
import template from '../views/edit_career_profile.html';
import angularModule from './careers_module';
import { determineTargetProgramType } from '../../determineTargetProgramType';
import { determineProgramPreferencesStateKey } from '../../determineProgramPreferencesStateKey';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('editCareerProfile', [
    '$injector',
    function factory($injector) {
        const $rootScope = $injector.get('$rootScope');
        const TranslationHelper = $injector.get('TranslationHelper');
        const AppHeaderViewModel = $injector.get('Navigation.AppHeader.AppHeaderViewModel');
        const DialogModal = $injector.get('DialogModal');
        const scopeTimeout = $injector.get('scopeTimeout');
        const SiteMetadata = $injector.get('SiteMetadata');
        const NavigationHelperMixin = $injector.get('Navigation.NavigationHelperMixin');
        const scrollHelper = $injector.get('scrollHelper');
        const $location = $injector.get('$location');
        const CareerProfile = $injector.get('CareerProfile');
        const EditCareerProfileHelper = $injector.get('EditCareerProfileHelper');
        const ClientStorage = $injector.get('ClientStorage');
        const ConfigFactory = $injector.get('ConfigFactory');
        const FixViewportOnBlur = $injector.get('FixViewportOnBlur');
        const $q = $injector.get('$q');
        const Cohort = $injector.get('Cohort');

        return {
            restrict: 'E',
            scope: {
                steps: '<',
                initialStep: '<?',
                user: '<?', // the user whose profile to edit (defaults to currentUser if not provided),
                stepsProgressMap: '<?',
                goToStep: '&?',
                programType: '=?',
            },
            templateUrl,

            link(scope, elem) {
                scope.disabledSaveSteps = {};
                scope.saveClickInterceptors = {};
                scope.saveNextClickInterceptors = {};

                scope.setDisableSaveForStep = (stepName, shouldDisable) => {
                    scope.disabledSaveSteps = { ...scope.disabledSaveSteps, [stepName]: shouldDisable };
                };

                scope.setSaveClickInterceptorForStep = (stepName, saveClickInterceptor) => {
                    scope.saveClickInterceptors = { ...scope.saveClickInterceptors, [stepName]: saveClickInterceptor };
                };

                scope.setSaveNextClickInterceptorForStep = (stepName, saveClickInterceptor) => {
                    scope.saveNextClickInterceptors = {
                        ...scope.saveNextClickInterceptors,
                        [stepName]: saveClickInterceptor,
                    };
                };

                scope.currentStepName = () => {
                    const currentPage = getCurrentPage();
                    const currentStep = scope.steps[currentPage - 1];
                    return currentStep.stepName;
                };

                scope.currentStepIsSaveDisabled = () => !!scope.disabledSaveSteps[scope.currentStepName()];

                NavigationHelperMixin.onLink(scope);
                scope.proxy = {};
                scope.helper = EditCareerProfileHelper;
                const translationHelper = new TranslationHelper('careers.edit_career_profile');

                AppHeaderViewModel.setBodyBackground('beige');
                AppHeaderViewModel.showAlternateHomeButton = false;

                setupBrandNameProperties($injector, scope, {
                    branding: () => scope.currentUser && Cohort.branding(scope.programType),
                });

                // the applicants editor doesn't need to scroll...
                if (!EditCareerProfileHelper.isApplicantEditor()) {
                    scrollHelper.scrollToTop();
                }

                // Default title
                SiteMetadata.updateHeaderMetadata();
                ConfigFactory.getConfig().then(config => {
                    scope.gdprAppliesToUser = config.gdprAppliesToUser();
                });

                //-----------------------------
                // Profile Data
                //-----------------------------

                Object.defineProperty(scope, 'currentUser', {
                    get() {
                        if (scope.user) return scope.user;
                        return $rootScope.currentUser;
                    },
                });

                // scope.careerProfile is a copy of the currentUser's career_profile and acts as a proxy,
                // allowing us to easily throw away changes if the user dirties the form and then navigates away
                // to another step without mucking up the career_profile on the currentUser.
                const getCareerProfileProxy = () => CareerProfile.new(scope.currentUser.career_profile.asJson());

                // Similar to scope.careerProfile, scope.programFamilyFormDatum acts as a proxy. We can update
                // the proxy without mutating anything on $rootScope.currentUser, and can easily throw away changes
                // if the user abandons their updates.
                const getProgramFamilyFormDatumProxy = () => {
                    const programFamily = scope.currentUser.relevantCohort.programFamily;
                    const programFamilyFormDatum = getProgramFamilyFormDatum(scope.currentUser, programFamily) || {
                        programFamily,
                    };

                    // Note that we use the JSON.parse(JSON.stringify(...)) trick here to create a DEEP copy
                    // of the programFamilyFormDatum. We can't use a SHALLOW copy here since it would share
                    // the same references as the source.
                    // Ultimately, I'd like to use `structuredClone()` here but it's not supported by all browsers in
                    // our browserslist. Potential optimization in the future.
                    // See also https://developer.mozilla.org/en-US/docs/Web/API/structuredClone.
                    return JSON.parse(JSON.stringify(programFamilyFormDatum));
                };

                // NOTE: Since this $watch on the currentUser only triggers if the REFERENCE to currentUser changes,
                // these lines should theoretically only run once, which is when this directive first renders.
                scope.$watch('currentUser', () => {
                    if (scope.currentUser) {
                        scope.careerProfile = getCareerProfileProxy();
                        scope.programFamilyFormDatum = getProgramFamilyFormDatumProxy();
                        scope.$watch('steps', setupFormSteps);
                    }
                });

                if (scope.helper.isApplication()) {
                    scope.programType = scope.currentUser.relevantCohort.programType;
                    scope.cohort = null; // fetched in submit_application
                    scope.disableSubstantialApplicationEdits = false;

                    // NOTE: if this list changes, then you probably want to change references to disableSubstantialApplicationEdits
                    scope.$watchGroup(
                        [
                            'careerProfile.birthdate',
                            'careerProfile.survey_years_full_time_experience',
                            'careerProfile.survey_highest_level_completed_education_description',
                        ],
                        () => {
                            // The master_of_business_administration program family is currently the only degree-granting
                            // program family we have that offers more than one program. When a user fills out an application
                            // for this program family, they're really applying for all of the programs at the same time.
                            // Based on the answers they provide to the fields that are being watched here,
                            // the program_type powering the application may need to be changed. The program(s)
                            // they'll be considered for will be communicated to the user on the Program Preferences
                            // form. If the applicant doesn't have the necessary requirements to be placed into
                            // a program, we set program_type to null so that the Program Preferences form will
                            // be invalid and prevent the applicant from submitting the application.
                            if (
                                scope.currentUser.relevantCohort.programFamily ===
                                ProgramFamily.master_of_business_administration
                            ) {
                                // Our recommendation based on the answers to the questions in the $watchGroup above.
                                //
                                // Read the explanation above the condition wrapping this logic, but I wanted to add a note here
                                // that this is confusing. We are implicitly relying on determineTargetProgramType to return null here
                                // if we don't find a program type to recommend because we didn't match up a program preferences state
                                // key with the Cohort's ProgramTypeConfig's programPreferenceStateKeys (meaning the user does not meet
                                // the requirements of the program).
                                //
                                // When determineTargetProgramType returns null, that has the side effect of disabling the Submit Application
                                // form because we won't lookup a Cohort record to apply to via scope.program_type. This is only relevant to the
                                // master_of_business_administration program family because the Cohort one is applying to changes based on one's
                                // answers to the properties in the $watch above. All other program families have one promoted Cohort at a given time
                                // and will have scope.programType and scope.cohort in the Submit Application form.
                                //
                                // But also know that Submit Application form submission can be disabled simply by the program preferences state
                                // having `saveButtonsDisabled: true`.
                                //
                                // I would LOVE to get rid of this and rely on the Submit Application Form's handling of `saveButtonsDisabled: true` to
                                // determine when to block application submission, BUT we'd also have to get rid of our program_type recommendation for
                                // applications to the master_of_business_administration program family in the process, which is a harder sell.
                                scope.programType = determineTargetProgramType(
                                    determineProgramPreferencesStateKey(scope.careerProfile),
                                    getActiveInstitution(scope.currentUser)?.id,
                                );
                            }
                        },
                    );

                    scope.$watch('currentUser', () => {
                        // If the user edits their account name or nickname, we want the career profile proxy to reflect that.
                        if (scope.careerProfile.name !== scope.currentUser.name) {
                            scope.careerProfile.name = scope.currentUser.name;
                        }

                        if (scope.careerProfile.nickname !== scope.currentUser.nickname) {
                            scope.careerProfile.nickname = scope.currentUser.nickname;
                        }
                    });

                    // We don't allow certain things in the Application to be changed after submission
                    if (getHasPendingProgramApplication(scope.currentUser)) {
                        scope.disableSubstantialApplicationEdits = true;
                    }
                }

                // NOTE: In the Profile, we know the programType and cohort immediately because the user has already submitted an application.
                if (scope.helper.isProfile()) {
                    scope.cohort = getCohort(scope.currentUser);
                    scope.programType = scope.cohort.programType;
                    scope.disableSubstantialApplicationEdits = false;
                }

                //-----------------------------
                // Multi-Step Form Config
                //-----------------------------

                function getCurrentPage() {
                    return parseInt($location.search().page, 10) || 1;
                }

                function setupFormSteps(newValue, oldValue) {
                    if (
                        !scope.careerProfile ||
                        !scope.steps ||
                        (scope.stepsInfo && JSON.stringify(newValue) === JSON.stringify(oldValue))
                    ) {
                        return;
                    }

                    // it's important to re-build steps in an async manner such that the multi-form-container is re-initialized
                    scope.stepsInfo = undefined;
                    scopeTimeout(scope, () => {
                        scope.stepsInfo = _.chain(scope.steps)
                            .map('stepName')
                            .map(section => {
                                // return a template that passes along necessary model info
                                // NOTE: all of these directives will use the shared scope
                                const directiveName = `${section.replace(/_/g, '-').snakeCase('-')}-form`;
                                const templateStr = `<${directiveName}></${directiveName}>`;
                                const title = translationHelper.get(section);

                                return {
                                    key: section,
                                    template: templateStr,
                                    title,
                                    hasForm: true,
                                    data: { stepName: section },
                                };
                            })
                            .value();
                    });
                }

                //-----------------------------
                // Step Navigation
                //-----------------------------

                // see also: form_helper.js::supportForm
                const formListener = scope.$on('formHelperCurrentForm', (_evt, formController) => {
                    scope.currentForm = formController;
                });
                scope.$on('$destroy', formListener);

                function scrollToContentTop() {
                    scopeTimeout(scope, () => {
                        const mainBox = $('.main-box');
                        const isFixed = mainBox.css('position') === 'fixed';
                        scrollHelper.scrollToTop(false, isFixed ? mainBox : undefined);
                    });
                }

                function updateLocationForIndex(index) {
                    if (EditCareerProfileHelper.isApplicantEditor()) {
                        $location.search('page', index + 1);
                    } else {
                        $location.url(`${$location.path()}?page=${index + 1}`);
                        scrollToContentTop();
                    }
                }

                // on scope for testing
                scope.gotoFormStep = index => {
                    scope.proxy.mobileNavExpanded = false;

                    if (getCurrentPage() - 1 === index) {
                        return;
                    }

                    updateLocationForIndex(index);
                };

                // This has to support the case of the user clicking the "Save and Next" button,
                // clicking one of the nav buttons, or clicking back on the browser.
                scope.$on('$locationChangeStart', event => {
                    // if navigating away from currentForm, delete invalidFields
                    delete scope.invalidFields;
                    delete scope.invalidLanguageFields;

                    // NOTE: we check for dirtied forms beyond just `currentForm`
                    // because we have sub-components that might be registering forms for
                    // their own validation, etc - which could be dirtied as well
                    if (elem.find('form.ng-dirty').length > 0) {
                        // Surprisingly, $location.url() already has the new location at this point,
                        // even though we can still cancel the navigation with event.preventDefault().
                        // We could also grab the target location from the second argument to the $locationChangeStart
                        // callback, but that would give us the full url with the hostname and all, which
                        // is less convenient.
                        const targetLocation = $location.url();
                        event.preventDefault();

                        DialogModal.confirm({
                            text: translationHelper.get('unsaved_changes_confirm'),
                            confirmCallback() {
                                scope.careerProfile = getCareerProfileProxy();
                                scope.programFamilyApplication = getProgramFamilyFormDatumProxy();
                                scope.currentForm?.$setPristine(true);
                                $location.url(targetLocation);
                            },
                        });
                    }
                });

                // HACK: in Facebook in-app browser, we need to guard against viewport bugs
                scope.$on('$locationChangeSuccess', () => {
                    FixViewportOnBlur.installBlurListenersOnLink(scope, elem);
                });
                FixViewportOnBlur.installBlurListenersOnLink(scope, elem);

                scope.$on('gotoFormStep', (_ev, index) => {
                    scope.gotoFormStep(index);
                });

                scope.$on('gotoEditProfileSection', (_ev, section) => {
                    const index = _.findIndex(scope.steps, step => step.stepName === section);
                    scope.gotoFormStep(index);
                });

                // set the initial page if provided directly
                if (scope.initialStep) {
                    $location.search({
                        page: scope.initialStep,
                    });
                }

                //-----------------------------
                // Form Submission
                //-----------------------------

                function showValidity() {
                    scope.currentForm.$setSubmitted(true);
                    return scope.currentForm.$valid;
                }

                scope.formActionsVisible = (isLast, _activeStep) => !isLast() || scope.helper.isApplicantEditor();

                scope.profileSubmitActionsVisible = isLast =>
                    scope.helper.isProfile() && !scope.helper.isApplicantEditor() && isLast();

                scope.handleSaveClick = () => {
                    if (scope.saveClickInterceptors[scope.currentStepName()]) {
                        scope.saveClickInterceptors[scope.currentStepName()]();
                    } else {
                        scope.save();
                    }
                };

                scope.save = () => scope.saveForm().catch(angular.noop);

                scope.saveForm = () => {
                    if (!showValidity()) {
                        scope.invalidFields =
                            (scope.currentForm.$error.required &&
                                scope.currentForm.$error.required
                                    .concat(scope.currentForm.$error['required-range'])
                                    .filter(f => typeof f === 'object')) ||
                            scope.currentForm.$error['required-range'];

                        scope.invalidLanguageFields = scope.currentForm.$error.language;
                        return $q.reject();
                    }

                    delete scope.invalidFields;
                    delete scope.invalidLanguageFields;

                    // see application_questions_form_dir.js
                    let shouldSaveForm = true;
                    scope.$broadcast('beforeSaveForm', _shouldSaveForm => {
                        shouldSaveForm = _shouldSaveForm;
                    });

                    if (!shouldSaveForm) {
                        return $q.reject();
                    }

                    ClientStorage.setItem(`saved_${scope.currentForm.$name}`, true);

                    scope.helper.logEventForApplication(`completed-${scope.currentForm.$name}`);

                    const completionPercentage = EditCareerProfileHelper.getCompletionPercentage(
                        scope.currentUser,
                        scope.careerProfile,
                        scope.programFamilyFormDatum,
                    );

                    const meta = {
                        // Transform this to snake_case for the server. We don't need to be destructive here, we can
                        // simply use a clone of scope.programFamilyFormDatum to send along in the meta
                        program_family_form_datum: transformKeyCase(scope.programFamilyFormDatum, {
                            to: 'snakeCase',
                        }),
                    };

                    if (EditCareerProfileHelper.isProfile() && completionPercentage === 100) {
                        // Only update this in the context of /settings/my-profile, never in the context of submitting a
                        // ProgramApplication. We know that users will not complete their CareerProfile as part of submitting
                        // a ProgramApplication (they complete it via an enrollment sidebar todo) since the application does
                        // not include the same steps / forms as the profile. If we set this here in the context of the application,
                        // we could erroneously mark a user's CareerProfile as complete because they've filled out all required
                        // forms / field for the application.
                        meta.complete = true;
                    }

                    return scope.careerProfile.save(meta).then(() => {
                        // After the save is successful, we need to set the form back to a pristine state so the form's
                        // $dirty state also gets set to false. Otherwise, even if the save is successfully complete,
                        // the confirmation dialog modal may pop up if the user tries to navigate to a different section
                        // because the form's $dirty state wasn't reset.
                        scope.currentForm?.$setPristine();

                        if (scope.currentUser) {
                            // We still want scope.careerProfile to act as a proxy (see the $watch on currentUser above),
                            // so we reset scope.currentUser.career_profile to a COPY of scope.careerProfile, which ensures
                            // that scope.currentUser.career_profile isn't a reference to scope.careerProfile, thus maintaining
                            // it's behavior as a proxy.
                            scope.currentUser.career_profile = CareerProfile.new(scope.careerProfile.asJson());
                        }

                        // The parents and/or children may need to listen for when the profile has been saved.
                        // See admin_edit_career_profile_dir.js, settings_dir.js, and submit_application_form_dir.js
                        // for where we listen to these events.
                        //
                        // NOTE: Order matters here (see submit_application_form_dir.js).
                        const savedCareerProfilePayload = { savedCompletionPercentage: completionPercentage };
                        scope.$broadcast('savedCareerProfile', savedCareerProfilePayload);
                        scope.$emit('savedCareerProfile', savedCareerProfilePayload);
                    });
                };

                scope.handleSaveAndNextClick = nextStepCallback => {
                    if (scope.saveNextClickInterceptors[scope.currentStepName()]) {
                        scope.saveNextClickInterceptors[scope.currentStepName()]();
                    } else {
                        scope.saveAndNext(nextStepCallback);
                    }
                };

                scope.saveAndNext = nextStepCallback => {
                    let currentFormWasIncompletePriorToSave;
                    if (EditCareerProfileHelper.isProfile()) {
                        currentFormWasIncompletePriorToSave = scope.currentFormIsIncomplete();
                    }

                    scope
                        .saveForm()
                        .then(() => {
                            // When we're in the career profile context, if the user has any incomplete form steps,
                            // navigate them to the next incomplete form step from the current form step or back
                            // to the first incomplete form step if no incomplete form steps can be found after
                            // the current form step.
                            if (EditCareerProfileHelper.isProfile()) {
                                const nextIncompleteStepIndex = scope.getNextIncompleteFormStepIndex();
                                if (nextIncompleteStepIndex > -1) {
                                    scope.gotoFormStep(nextIncompleteStepIndex);
                                    return;
                                }
                                if (currentFormWasIncompletePriorToSave) {
                                    scope.gotoFormStep(scope.steps.length - 1);
                                    return;
                                }
                            }

                            if (nextStepCallback) {
                                nextStepCallback();
                                scrollToContentTop();
                            } else if (EditCareerProfileHelper.isApplication()) {
                                scope.loadRoute(`${$location.path()}?page=${scope.steps.length - 1}`);
                            }
                        })
                        .catch(angular.noop);
                };

                scope.currentFormIsIncomplete = () => {
                    if (!scope.stepsProgressMap) {
                        throw new Error(
                            'scope.stepsProgressMap must be defined to determine if the form was incomplete prior to save.',
                        );
                    }

                    const currentPage = getCurrentPage();
                    const currentStep = scope.steps[currentPage - 1];
                    return scope.stepsProgressMap[currentStep.stepName] === 'incomplete';
                };

                scope.getNextIncompleteFormStepIndex = () => {
                    if (!scope.stepsProgressMap) {
                        throw new Error(
                            'scope.stepsProgressMap must be defined to determine the next incomplete form step.',
                        );
                    }

                    const currentPage = getCurrentPage(); // 1-indexed

                    // If the user isn't on the last page, check if any of the form steps after
                    // the currentPage are incomplete and take them to the first one that we find.
                    for (let i = currentPage; i < scope.steps.length; i++) {
                        if (scope.stepsProgressMap[scope.steps[i].stepName] === 'incomplete') {
                            return i;
                        }
                    }

                    // Otherwise, check if any of the form steps before the currentPage are incomplete.
                    for (let i = 0; i < currentPage - 1; i++) {
                        if (scope.stepsProgressMap[scope.steps[i].stepName] === 'incomplete') {
                            return i;
                        }
                    }

                    return -1;
                };
            },
        };
    },
]);
