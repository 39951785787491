import { type CareerProfileIguanaObject } from 'CareerProfiles';
import { ProgramPreferencesStateKey } from './Careers.types';

// FIXME: this is not multi-program compatible and will return business_admin_* states for
// programs NOT in the master_of_business_administration program family.
// At the moment, that does not matter because we only display things from these configs on
// the Submit Application form if:
//  1. The config says that saves should be disabled, in which case the wording is generic enough
//     to work for any program, or
//  2. The program family of the Cohort being applied to is master_of_business_administration, because we
//     generally consider applications to that program family for multiple programs.
// If we ever want to display this stuff outside of those two contexts above, we need to rethink this.
export function determineProgramPreferencesStateKey(
    careerProfile: CareerProfileIguanaObject,
): ProgramPreferencesStateKey {
    const isLowerEducation = ['associates', 'high_school'].includes(
        careerProfile.survey_highest_level_completed_education_description as string,
    );

    if (isLowerEducation) {
        return ProgramPreferencesStateKey.generic_minimum_education_not_met;
    }

    if (careerProfile.survey_years_full_time_experience === '0_1') {
        return ProgramPreferencesStateKey.business_admin_minimum_experience;
    }

    if (careerProfile.survey_years_full_time_experience === '2_6' && careerProfile.age && careerProfile.age < 29) {
        return ProgramPreferencesStateKey.business_admin_younger_with_low_experience;
    }

    if (careerProfile.survey_years_full_time_experience === '2_6' && careerProfile.age && careerProfile.age >= 29) {
        return ProgramPreferencesStateKey.business_admin_older_with_low_experience;
    }

    // Age is not a factor for this state
    if (careerProfile.survey_years_full_time_experience === '7_10') {
        return ProgramPreferencesStateKey.business_admin_middle_experience;
    }

    if (
        ['11_15', '16_plus'].includes(careerProfile.survey_years_full_time_experience as string) &&
        careerProfile.age &&
        careerProfile.age < 31
    ) {
        return ProgramPreferencesStateKey.business_admin_younger_with_high_experience;
    }

    // All other scenarios have been handled above, so this is the only possible outcome left.
    // In this state, `survey_years_full_time_experience` is either '11_15' or '16_plus' and
    // the user's age must be greater than or equal to 31.
    return ProgramPreferencesStateKey.business_admin_older_with_high_experience;
}

export default determineProgramPreferencesStateKey;
