export interface EditCareerProfileHelperType {
    isApplicantEditor: () => boolean;
}

export interface ProgramPreferencesBlurbLogoConfig {
    alt: string;
    classes: string[];
    imgSrc: string | null;
    imgStyles: Record<string, string> | null;
}
export interface ProgramPreferencesBlurb {
    classes?: string[];
    content: string;
    heading?: string;
    logoConfig?: ProgramPreferencesBlurbLogoConfig;
    show: boolean;
}

export interface ProgramPreferencesConfig {
    blurbs: ProgramPreferencesBlurb[];
    classes?: string[];
    saveButtonsDisabled?: boolean;
    showValarOptIn(): boolean;
    showInterviewPref?: boolean;
}

export enum ProgramPreferencesStateKey {
    generic_minimum_education_not_met = 'generic_minimum_education_not_met',
    business_admin_minimum_experience = 'business_admin_minimum_experience',
    business_admin_younger_with_low_experience = 'business_admin_younger_with_low_experience',
    business_admin_older_with_low_experience = 'business_admin_older_with_low_experience',
    business_admin_middle_experience = 'business_admin_middle_experience',
    business_admin_younger_with_high_experience = 'business_admin_younger_with_high_experience',
    business_admin_older_with_high_experience = 'business_admin_older_with_high_experience',
}
