const config = {
    build_number: '599037',
    build_timestamp: '2025-02-13T16:22:35.150Z',
    build_commit: '1b448e8458815a998671db2cdf9a3d65b5dc0d77',
};

// locally, the build number is always 4122 (which allows us to load non-English content locally)
if (Number.isNaN(parseInt(config.build_number, 10))) {
    config.build_number = '4122';
}

export default config;
